import 'bootstrap';
import 'slick-carousel';
import AOS from 'aos';

$(document).ready(function(){

	/* ------------------------------------------------------------------------ */
	/* SLIDER */
	/* ------------------------------------------------------------------------ */ 

	$(".hero-slider").slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		fade: true,
		dots: true,
		prevArrow:"<button type='button' class='slick-prev'><i class='icomoon icon-chevron-left' aria-hidden='true'></i></button>",
		nextArrow:"<button type='button' class='slick-next'><i class='icomoon icon-chevron-right' aria-hidden='true'></i></button>",
	});

	/*var headbannersrc = $('.slide-bg').attr('src');
	$('.hero-slider').children('.headimg').css("background-image","url(" + headbannersrc + ")");*/

	$('.hero-slider .slide').each(function() {
		var headimgsrc = $(this).children('.slide-bg').attr('src');
		$(this).parents('.slick-slide').css("background-image","url(" + headimgsrc + ")");
	});

	$(".temoignage-slider").slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		prevArrow:"<button type='button' class='slick-prev'><i class='icomoon icon-chevron-left' aria-hidden='true'></i></button>",
		nextArrow:"<button type='button' class='slick-next'><i class='icomoon icon-chevron-right' aria-hidden='true'></i></button>",
		responsive: [
		    {
		      breakpoint: 1280,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 2,
		        infinite: true,
		      }
		    },
		    {
		      breakpoint: 768,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
		    },
		    {
		      breakpoint: 520,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1,
		        arrows: false,
		      }
		    }
	    ] 
	});

	$(".slider-real").slick({
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow:"<button type='button' class='slick-prev'><i class='icomoon icon-chevron-left' aria-hidden='true'></i></button>",
		nextArrow:"<button type='button' class='slick-next'><i class='icomoon icon-chevron-right' aria-hidden='true'></i></button>",
	});


		/* ------------------------------------------------------------------------ */
		/* SETUP MENU */
		/* ------------------------------------------------------------------------ */ 

	if( 'ontouchstart' in window ){ var click = 'touchstart'; }
	else { var click = 'click'; }


		/* ------------------------------------------------------------------------ */
		/* MENU OPEN RESPONSIVE */
		/* ------------------------------------------------------------------------ */ 

	$('div.burger').on(click, function(){
		if( !$(this).hasClass('open') ){ openMenu(); } 
		else { closeMenu(); }
	});	

	function openMenu(){
		$('nav.menu').addClass('animate');					

		$('div.burger').addClass('open');
		$('div.burger-wrap').addClass('open');	
		//$('body').css('overflow', 'hidden');
		
		setTimeout(function(){ 
			$('div.y').hide(); 
			$('div.x').addClass('rotate30'); 
			$('div.z').addClass('rotateneg'); 
		}, 70);		
	}
		
	function closeMenu(){
		setTimeout(function(){ 			
			$('div.burger-wrap').removeClass('open');
			$('div.burger').removeClass('open');				
			$('nav.menu').removeClass('animate');
			//$('body').css('overflow', 'initial');
			
			setTimeout(function(){ 			
				$('div.x').removeClass('rotate30'); 
				$('div.z').removeClass('rotateneg'); 			
			}, 50);
			setTimeout(function(){
				$('div.y').show();
			}, 70);
		}, 100);														
	}

		/* ------------------------------------------------------------------------ */
		/* HEAD BANNER */
		/* ------------------------------------------------------------------------ */ 


	// media query event handler
	if (matchMedia) {
		const mq = window.matchMedia("(max-width: 576px)");
		mq.addListener(WidthChange);
		WidthChange(mq);
	}

	// media query change
	function WidthChange(mq) {
		if (mq.matches) {
			$('.head-banner').each(function() {
				var herosrc = $(this).children('.hero-wrap').children('.hero').attr('src');
				$(this).css("background-image","url(" + herosrc + ")");
				$(this).children('.hero-wrap').children('.hero').css("opacity","0");
			});
		} else {
			$('.head-banner').each(function() {
				var herosrc = $(this).children('.hero-wrap').children('.hero').attr('src');
				$(this).css("background-image","none");
				$(this).children('.hero-wrap').children('.hero').css("opacity","1");
			});
		}
	}

		/* ------------------------------------------------------------------------ */
		/* ABOUT LAYOUT */
		/* ------------------------------------------------------------------------ */ 

	$(window).on("load resize",function(){
		var layout1 = $('.test').height();
		var layout2 = $('.aside-about').height();
		$('.layoutprint').css("height",layout1 + layout2 + 100);
	});



		/* ------------------------------------------------------------------------ */
		/* AOS */
		/* ------------------------------------------------------------------------ */ 

	AOS.init({
		 once: true,
	});

	$('#contactSubmit').click(function(e){
		e.preventDefault();
    	var formdata = new FormData($('#contactForm').get(0));

		$.ajax({
		    type: 'POST',
		    cache: false,
		    dataType: "JSON",
		    url: 'validation.php',
		    data: formdata,
	      	success: function(response) {
		        if (response) {
		        	html = '<div class="alert">';
		        	html += response;
		        	html += '</div>';
		        	$('.form-info').html(html);
		        } else {
		        	html = '<div class="alert">';
		        	html += 'Erreur lors de votre demande, veuillez réessayer';
		        	html += '</div>';
		        	$('.form-info').html(html);
		        }
	      	},
      		error: function(response) {
		        html = '<div class="alert">';
		        html += 'Erreur lors de votre demande, veuillez réessayer';
		        html += '</div>';
		        $('.form-info').html(html);
      		},
		});
	});

}); //end jquery